import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import {
  SectionLayout,
  sectionProps,
} from "../../../components/page-sections/info-per-il-pubblico/layout";

const CreditoCOnsapevolePage: React.FC = () => {
  return (
    <SectionLayout page="credito-consapevole" title="Credito consapevole">
      <Box {...sectionProps}>
        <Stack spacing={4} direction={"column"} textAlign={"justify"}>
          <Heading
            as={"h1"}
            fontSize={["24px", "32px"]}
            fontWeight={"400"}
            textAlign={"left"}
            fontFamily={"body"}
            color={"accent.01"}
          >
            Credito consapevole
          </Heading>
          <Text>
            La Banca, da sempre attenta al buon rapporto con i propri clienti,
            ritiene che una comunicazione chiara e trasparente tra chi eroga il
            credito e chi lo richiede contribuisca a soddisfare le esigenze
            informative dei consumatori ed allo stesso tempo promuovere un
            credito responsabile.
          </Text>
          <Text>
            Per tale motivo, aderendo ad alcune delle principali Associazioni di
            Categoria, ha previsto all’interno del proprio sito internet
            un’apposita sezione dedicata alla educazione finanziaria finalizzata
            ad accrescere la conoscenza dei prodotti finanziari offerti.
          </Text>
          <Text>
            Se si vuole chiedere un prestito, un mutuo o pagare a rate un bene /
            servizio, MONITORATA aiuta a capire se le “entrate” / “uscite”
            mensili attuali sono compatibili con la rata da pagare. I risultati
            sono forniti in modo semplice ed immediato insieme ad alcune utili
            indicazioni, scritte in forma semplice e concisa.
          </Text>
          <Text>
            Puoi scoprire MONITORATA gratuitamente cliccando su{" "}
            <Link
              color={"accent.01"}
              href="https://www.monitorata.it"
              isExternal
            >
              www.monitorata.it
            </Link>
            . Coloro che lo utilizzano rimangono anonimi e non è richiesta
            nessuna iscrizione né partnership commerciale.
          </Text>
          <Heading
            as="h5"
            fontSize="lg"
            fontWeight={"400"}
            textAlign={"left"}
            fontFamily={"body"}
            color={"accent.01"}
          >
            Che cosa è il sovra indebitamento?
          </Heading>
          <Text>
            Il sovra indebitamento è una situazione di eccessivo indebitamento
            che si può manifestare in concomitanza con il sopraggiungere di più
            eventi che possono colpire il Consumatore:
          </Text>
          <UnorderedList>
            <ListItem>Perdita del lavoro;</ListItem>
            <ListItem>Malattia</ListItem>
            <ListItem>
              Eventi familiari (es. divorzio, separazione, decesso di un membro
              della famiglia ecc...)
            </ListItem>
          </UnorderedList>
          <Text>
            In tali ipotesi, il Consumatore può trovarsi nella difficoltà di
            onorare con puntualità e integralmente le spese quotidiane, quali il
            pagamento delle bollette o la rata del finanziamento.
          </Text>
          <Heading
            as="h5"
            fontSize="lg"
            fontWeight={"400"}
            textAlign={"left"}
            fontFamily={"body"}
            color={"accent.01"}
          >
            Come si può prevenire il sovra indebitamento?
          </Heading>
          <Text>
            Avendo un comportamento consapevole ed attento, valutando ad esempio
            il proprio budget, amministrando le proprie risorse con prudenza
            alla luce del bilancio familiare disponibile. Prima di decidere di
            contrarre un nuovo finanziamento, ad esempio, il Consumatore deve
            riflettere sulla sua capacità di rimborsare le rate, sulla base
            delle spese da sostenersi mensilmente. Dal canto suo, il
            Finanziatore si impegna, all'atto della richiesta di un
            finanziamento, a valutare con attenzione il profilo creditizio del
            richiedente, informando in maniera chiara il Consumatore, proponendo
            delle soluzioni finanziarie adeguate e rifiutando l'erogazione del
            credito, qualora le valutazioni effettuate lo rendano necessario.
          </Text>
          <Heading
            as="h5"
            fontSize="lg"
            fontWeight={"400"}
            textAlign={"left"}
            fontFamily={"body"}
            color={"accent.01"}
          >
            Cosa fare in caso di sovra indebitamento?
          </Heading>
          <Text>
            Può accadere che nonostante le accortezze adottate, il Consumatore
            si trovi comunque in una situazione di sovra indebitamento. In
            questo caso, non bisogna esitare a contattare il Finanziatore,
            rappresentandogli con precisione e chiarezza la situazione di
            difficoltà e le relative cause, in modo da verificare con
            quest'ultimo se vi siano soluzioni che permettano di superare il
            problema insorto.
          </Text>
        </Stack>
      </Box>
    </SectionLayout>
  );
};

export default CreditoCOnsapevolePage;
